const CONSENT_COOKIE_NAME = "1862_digital_archives_cookie_policy";
const ANALYTICS_COOKIE_NAMES = ["_ga", "_gid", "_gat"];
const TRACKING_ID_META_TAG_NAME = "tracking-id";

/**
 * This came from the {@link https://github.com/alphagov/digitalmarketplace-govuk-frontend/blob/4a56efe195a736aec4bd4c28224527f86f315813/src/digitalmarketplace/helpers/cookie/cookie-functions.js|GOV UK GitHub Repo}.
 * @param {string} name The name of the cookie to search for.
 */
const getCookie = (name) => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (const cookie in cookies) {
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return decodeURIComponent(cookie.substring(nameEQ.length));
    }
  }
  return null;
};

/**
 * This came from the {@link https://github.com/alphagov/digitalmarketplace-govuk-frontend/blob/4a56efe195a736aec4bd4c28224527f86f315813/src/digitalmarketplace/helpers/cookie/cookie-functions.js|GOV UK GitHub Repo}.
 * @param {string} name The name of the cookie to search for.
 * @param {string} value The value to set for the cookie.
 * @param {number} days The number of days to store the cookie.
 */
const setCookie = (name, value, days) => {
  var cookieString = name + "=" + value + "; path=/";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    cookieString = cookieString + "; expires=" + date.toGMTString();
  }
  if (document.location.protocol === "https:") {
    cookieString = cookieString + "; Secure";
  }

  document.cookie = cookieString;
};

/**
 * @param {string} name The cookie to remove.
 */
const deleteCookie = (cookieName) => {
  setCookie(cookieName, "", -1);
};

const getConsent = () => {
  const consentCookie = getCookie(CONSENT_COOKIE_NAME);

  if (consentCookie && shouldGiveConsent) {
    try {
      return JSON.parse(consentCookie);
    } catch (err) {
      return false;
    }
  }

  return false;
};

const setConsentCookie = (shouldGiveConsent) => {
  // Delete existing analytics cookies if we don't have consent
  if (!shouldGiveConsent) {
    for (const cookie in ANALYTICS_COOKIE_NAMES) {
      deleteCookie(cookie);
    }
  }

  setCookie(CONSENT_COOKIE_NAME, JSON.stringify(shouldGiveConsent), 365);
};

/**
 * Clear any cookies set by the previous version of the digital archives.
 * This was the {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gtagjs_and_analyticsjs_universal_analytics_-_cookie_usage|Google Tag Manager cookies} and the {@link http://cookiecuttr.com/|CookieCuttr cookie} set by {@link https://github.com/collective/collective.cookiecuttr|collective.cookiecuttr}.
 *
 */
const clearOldCookies = () => {
  const oldCookies = [...ANALYTICS_COOKIE_NAMES, "cc_cookie_accept"];

  oldCookies.forEach((cookie) => {
    if (getCookie(cookie)) {
      var cookieString =
        cookie +
        "=;expires=" +
        new Date() +
        ";domain=" +
        window.location.hostname.replace(/^www\./, ".") +
        ";path=/";

      document.cookie = cookieString;
    }
  });
};

/** @param {MouseEvent} event The input event that triggered this. */
const hideCookieMessage = (event) => {
  const cookieBannerConfirmation = document.getElementById(
    "cookie-banner-confirmation"
  );
  if (!cookieBannerConfirmation) {
    console.error("No cookie confirmation banner could be found.");
    return;
  }
  cookieBannerConfirmation.style.display = "none";

  if (event.target) {
    event.preventDefault();
  }
};

/** @param {boolean} analyticsConsent Whether to set the consent cookie */
const setCookieConsent = (analyticsConsent) => {
  setConsentCookie(analyticsConsent);
  showConfirmationMessage(analyticsConsent);
};

const showConfirmationMessage = (analyticsConsent) => {
  // Set the message to show
  const messagePrefix = analyticsConsent
    ? "You’ve accepted analytics cookies. "
    : "You’ve rejected analytics cookies. ";
  const cookieBannerConfirmationMessage = document.querySelector(
    "#cookie-banner-confirmation .govuk-cookie-banner__content p"
  );
  if (!cookieBannerConfirmationMessage) {
    console.error("No cookie question confirmation message could be found.");
    return;
  }
  cookieBannerConfirmationMessage.insertAdjacentText(
    "afterbegin",
    messagePrefix
  );

  // Hide the question banner
  const cookieBannerQuestion = document.getElementById(
    "cookie-banner-question"
  );
  if (!cookieBannerQuestion) {
    console.error("No cookie question banner could be found.");
    return;
  }
  cookieBannerQuestion.style.display = "none";

  // Show the message and focus it
  const cookieBannerConfirmation = document.getElementById(
    "cookie-banner-confirmation"
  );
  if (!cookieBannerConfirmation) {
    console.error("No cookie confirmation banner could be found.");
    return;
  }
  cookieBannerConfirmation.style.display = "block";
  cookieBannerConfirmation.focus();
};

const insertAnalytics = () => {
  const metaTags = document.getElementsByTagName("meta");
  let trackingIdMetaTag;

  for (const element of metaTags) {
    if (element.getAttribute("name") === TRACKING_ID_META_TAG_NAME) {
      trackingIdMetaTag = element;
    }
  }

  if (!trackingIdMetaTag) {
    console.error("No analytics meta tag found");
    return;
  }

  const trackingId = trackingIdMetaTag["content"];

  if (!trackingId) {
    console.error("No analytics ID found");
    return;
  }

  // Add tag manager script to head
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

  document.head.appendChild(script);

  // Copied from the Google Analytics docs: https://developers.google.com/analytics/devguides/collection/analyticsjs
  // (function (i, s, o, g, r, a, m) {
  //   {
  //     i["GoogleAnalyticsObject"] = r;
  //     (i[r] =
  //       i[r] ||
  //       function () {
  //         {
  //           (i[r].q = i[r].q || []).push(arguments);
  //         }
  //       }),
  //       (i[r].l = 1 * new Date());
  //     (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
  //     a.async = 1;
  //     a.src = g;
  //     m.parentNode.insertBefore(a, m);
  //   }
  // })(
  //   window,
  //   document,
  //   "script",
  //   "https://www.google-analytics.com/analytics.js",
  //   "ga"
  // // );

  // ga("create", trackingId, "auto");
  // ga("send", "pageview");
  // ga("set", "anonymizeIp", true);

  // Copied from GA4 migration docs: https://developers.google.com/analytics/devguides/migration/ua/analyticsjs-to-gtagjs
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", trackingId);
};

document.addEventListener("DOMContentLoaded", function (event) {
  /** @type {HTMLButtonElement} */
  const confirmationDialogHideButton = document.getElementById(
    "cookie-banner-confirmation-hide-button"
  );
  if (confirmationDialogHideButton) {
    confirmationDialogHideButton.onclick = (event) => {
      hideCookieMessage(event);
    };
  }

  /** @type {HTMLButtonElement} */
  const acceptCookiesLink = document.querySelector(
    `.govuk-cookie-banner button[name="cookies"][value="accept"]`
  );
  if (acceptCookiesLink) {
    acceptCookiesLink.onclick = () => {
      setCookieConsent(true);
      insertAnalytics();
    };
  }

  /** @type {HTMLButtonElement} */
  const rejectCookiesLink = document.querySelector(
    `.govuk-cookie-banner button[name="cookies"][value="reject"]`
  );

  if (rejectCookiesLink) {
    rejectCookiesLink.onclick = () => {
      setCookieConsent(false);
    };
  }

  // Not the most accurate as this could be in another cookie or a value, but highly unlikely
  if (document.cookie.indexOf("1862_digital_archives_cookie_policy") > -1) {
    insertAnalytics();
  }
});
